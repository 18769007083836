// ==========================================================================
// Buttons
// ==========================================================================

.button {
  background-color: transparent;
  border: 2px solid $cream-cran;
  color: $cream-cran;
  cursor: pointer;
  display: inline-block;
  font-size: 2.2rem;
  font-weight: $bold;
  line-height: 1;
  padding: 2rem 3rem;
  text-align: center;
  text-decoration: none;
  transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out;

  &:hover {
    background-color: $cream-cran;
    color: $black;
  }
}

.button--disabled,
.button[disabled] {

}