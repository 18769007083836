// ==========================================================================
// Base
// ==========================================================================

*,
*:before,
*:after {
  box-sizing: border-box;
}

*:focus,
*:hover,
*:active {
  outline: 0 none;
}

html {
  background: $black url('../img/svg/ateliernoirnoir.svg') no-repeat center center;
  background-size: 10rem;
  font-size: 40%;
  overflow: hidden;
  position: relative;
  height: 100%;

  &.dom-loaded {
    overflow: visible;
  }
}

html,
html a {
  -webkit-font-smoothing: antialiased;
  text-shadow: 1px 1px 1px rgba(0,0,0,0.004);
}

body {
  color: $color-foreground;
  font-family: $font-default;
  font-size: 1.6rem;
  line-height: 1.5;
  min-height: 100%;
  overflow-x: hidden;
  text-size-adjust: none;

  &.navigation--opened {
    overflow: hidden;
  }
}

.loader {
  animation-delay: 1s;
  animation-duration: 1s;
  animation-fill-mode: both;
  background-color: $black;
  bottom: 0;
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 50;

  .loader__icon {
    animation-duration: 3s;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
    animation-name: rotateLoader;
    background: url('../img/svg/ateliernoirnoir.svg') no-repeat center center;
    background-size: 18rem;
    display: block;
    height: 18rem;
    left: 50%;
    margin: -9rem 0 0 -9rem;
    position: absolute;
    top: 50%;
    width: 18rem;

    .icon {
      height: 18rem;
      width: 18rem;
    }
  }

  &:after {
    bottom: 6rem;
    color: $white;
    content: 'Chargement...';
    display: block;
    font-family: arial, sans-serif;
    font-size: 1.6rem;
    font-weight: $extralight;
    line-height: 1;
    position: absolute;
    text-align: center;
    width: 100%;
  }

  .dom-loaded & {
    animation-name: fadeOutLoader;
    display: none \9;
  }
}

@keyframes rotateLoader {
  from {
    transform: rotate(0deg);
  }

  33% {
    transform: rotate(180deg);
  }

  66% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes fadeOutLoader {
  from {
    opacity: 1;
    z-index: 50;
  }

  to {
    opacity: 0;
    z-index: -1;
  }
}

@media (min-width: $screen-sm) {
  html {
    font-size: 50%;
  }
}

@media (min-width: $screen-md) {
  html {
    font-size: 62.5%;
  }
}

#outdated {
  display: none;
}