// ==========================================================================
// Header
// ==========================================================================

.header {
  background-color: $heavy-metal;
  background-position: center top;
  background-size: cover;
  overflow: hidden;
  position: relative;
  z-index: 21;
}

.header__scroll-down {
  bottom: 5rem;
  left: 50%;
  margin-left: -3rem;
  position: absolute;
  z-index: 10;

  &:after {
    background-color: #8f8f8b;
    content: '';
    display: block;
    height: 50vh;
    left: 2.95rem;
    position: absolute;
    top: 10.5rem;
    width: 1px;
    z-index: 10;
  }
}

.header__icon {
  animation: wobbleScroll 10s ease 5s infinite;
  height: 8.5rem;
  width: 6rem;
}

.header__holder {
  height: 568px;

  @extend .block-table;
}

.header__inner {
  text-align: center;
  position: relative;

  @extend .block-table__cell;
}

.header__shadow {
  border-radius: 22.5rem;
  box-shadow: 0 0 0 1500px rgba(0, 0, 0, 0.5);
  height: 45rem;
  margin: 0 auto;
  padding: 10rem;
  position: relative;
  transform: rotate(-45deg);
  width: 45rem;

  &:before,
  &:after {
    background-color: $cream-cran;
    content: '';
    display: block;
    height: 0.5rem;
    margin-top: -0.25rem;
    position: absolute;
    top: 50%;
    width: 6.5rem;
  }

  &:before {
    left: -8.5rem;
  }

  &:after {
    right: -8.5rem;
  }
}

.header__menu {
  animation: fadeInUpMenu 1s ease 2s 1 both;
  backface-visibility: hidden;
  background: $cream-cran;
  border: 0 none;
  border-radius: 4.5rem;
  cursor: pointer;
  font-size: 2.5rem;
  letter-spacing: -0.25rem;
  line-height: 2rem;
  height: 8.5rem;
  padding: 0;
  position: absolute;
  right: -9.5rem;
  top: 12rem;
  transform: rotate(45deg);
  transition: background-color 0.25s ease, color 0.25s ease;
  width: 8.5rem;

  &:hover {
    background: $black;
    color: $white;
  }
}

.header__dashed {
  border: 1px dashed $white;
  border-radius: 13rem;
  height: 25rem;
  margin: 0 auto;
  transform: rotate(45deg);
  width: 25rem;
}

.header__logo {
  height: 18rem;
  margin-top: 3.5rem;
  width: 18rem;
}

.navbar {
  position: fixed;
  right: 15px;
  top: 3.5rem;
  z-index: 20;
}

.navbar__menu {
  background: $cream-cran;
  border: 0 none;
  border-radius: 4.5rem;
  cursor: pointer;
  font-size: 2.5rem;
  letter-spacing: -0.25rem;
  line-height: 2rem;
  height: 8.5rem;
  padding: 0;
  transition: background-color 0.25s ease, color 0.25s ease;
  width: 8.5rem;

  &:hover {
    background: $black;
    color: $white;
  }
}

.navbar__home {
  display: block;
  height: 8.5rem;
  margin-top: 2.5rem;
  width: 8.5rem;

  .icon {
    height: 8.5rem;
    width: 8.5rem;
  }
}

@keyframes wobbleScroll {
  from {
    transform: none;
  }

  2% {
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
  }

  4% {
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
  }

  6% {
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
  }

  8% {
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
  }

  10% {
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
  }

  to {
    transform: none;
  }
}

@keyframes fadeInUpMenu {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0) rotate(45deg);
  }

  to {
    opacity: 1;
    transform: rotate(45deg);
  }
}

@media (min-width: $screen-sm) {
  .header__scroll-down {
    bottom: 8rem;
  }

  .header__holder {
    height: 768px;
  }
}

@media (min-width: $screen-md) {
  .header__scroll-down {
    bottom: 5rem;
  }

  .header__holder {
    height: 100vh;
    min-height: 780px;
  }
}

@media (min-width: $screen-lg) {
  .header__scroll-down {
    bottom: 10rem;
  }

  .header__holder {
    height: 100vh;
    min-height: 850px;
  }
}