// ==========================================================================
// Page studio
// ==========================================================================

.page-studio {
  background: $black;
  color: $white;
}

.page-studio__header {
  position: relative;

  &:before {
    background: $heavy-metal;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 25%;
    top: 0;
    z-index: 12;
  }
}

.page-studio__title {
  background: $heavy-metal;
  display: inline-block;
  font-size: 2.2rem;
  font-weight: $extralight;
  line-height: 6rem;
  margin: 0;
  min-width: 50%;
  padding-right: 6rem;
  position: relative;
  z-index: 13;

  .icon {
    height: 4rem;
    margin-right: 1rem;
    width: 4rem;
  }
}

.page-studio__left {
  padding-right: 0;
  position: relative;
  z-index: 10;

  &--first {
    margin-top: -6rem;
  }
}

.page-studio__right {
  padding-left: 0;

  &:not(.page-studio__right--first) {
  }
}

.page-studio__photography--left {
  float: right;
  max-height: 800px;
  width: auto;
}

.page-studio__photography--right {
  max-height: 935px;
  width: auto;
}

.page-studio__content {
  padding-top: 6rem;
  position: relative;

  h2 {
    color: $cream-cran;
    font-size: 2.2rem;
    font-weight: $extralight;
    line-height: 1.3;
    margin: 6rem 0 3rem;

    &:first-child {
      margin-top: 0;
    }
  }

  h3 {
    font-weight: $bold;
    line-height: 2.4rem;
    margin: 0;
    text-transform: uppercase;
  }

  p {
    margin: 0 0 2rem;
  }

  span {
    color: $cream-cran;
    display: block;
    font-size: 1.4rem;
    line-height: 1.8rem;
  }

  a {
    color: $cream-cran;
  }

  strong,
  b {
    color: $cream-cran;
    font-weight: $bold;
  }
}

.page-studio__content--left {
  float: right;
  max-width: 585px;
  padding: 6rem 15px 4rem 0;
}

.page-studio__content--right {
  max-width: 585px;
  padding-left: 6rem;
}

.page-studio__icon {
  border: 1px dashed $white;
  border-radius: 4rem;
  display: none;
  height: 8rem;
  margin-right: -4rem;
  position: absolute;
  right: 50%;
  top: -4rem;
  transform: rotate(-45deg);
  width: 8rem;

  &:before,
  &:after {
    background-color: $cream-cran;
    content: '';
    display: block;
    height: 0.3rem;
    margin-top: -0.15rem;
    position: absolute;
    top: 50%;
    width: 3.4rem;
  }

  &:before {
    left: -5rem;
  }

  &:after {
    right: -5rem;
  }
}

.page-studio__holder {
  padding-bottom: 4rem;
  position: relative;
  z-index: 1;

  &:before {
    background: $heavy-metal;
    bottom: 0;
    content: '';
    display: block;
    left: 0;
    position: absolute;
    top: 0;
    width: 1250px;
    z-index: -1;
  }

  &:after {
    clear: both;
    content: '';
    display: table;
  }

  &--left:before{
    content: none;
  }
}

.page-studio__square {
  animation-fill-mode: both;
  background-color: $cream-cran;
  height: 6rem;
  margin-bottom: 2rem;
  width: 6rem;

  &--left {
    margin: 6rem 0 0;
  }
}

.page-studio__rectangle {
  animation-fill-mode: both;
  background: $black;
  height: 6rem;
  margin: 6rem 0 0 6rem;
  max-width: 525px;
}

@media (max-width: 767px) {
  .page-studio__holder {
    height: auto !important;
  }
}

@media (min-width: $screen-sm) {
  .page-studio__header {
    &:before {
      right: 70%;
    }
  }

  .page-studio__left--first {
    padding-left: 0;
  }

  .page-studio__right {
    &--first {
      margin-top: -6rem;
    }

    &:not(.page-studio__right--first) {
      padding-right: 0;
    }
  }

  .page-studio__content--left {
    padding-right: 6rem;
  }

  .page-studio__holder {
    padding-bottom: 0;
  }

  .page-studio__inner {
    left: 0;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
  }

  .page-studio__icon {
    display: block;
    margin-right: 0;
    right: -4rem;
  }

  .page-studio__square--left {
    display: none;
  }

  .page-studio__rectangle {
    display: none;
  }
}

@media (min-width: $screen-lg) {
  .page-studio__icon {
    border-radius: 7rem;
    height: 14rem;
    right: -7rem;
    top: -7rem;
    width: 14rem;

    &:before {
      left: -8rem;
    }

    &:after {
      right: -8rem;
    }
  }

  .page-studio__content--right {
    padding-top: 0;
  }

  .page-studio__rectangle {
    display: block;
  }

  .page-studio__square--left {
    display: block;
  }
}