// ==========================================================================
// Main
// ==========================================================================

.main {

}

.thematic {
  background-color: $heavy-metal;
  color: $white;

  *[class^='col-'] {
    padding: 0;
  }
}

  .thematic__left {
    padding: 12rem 0 4.5rem;

    .button {
      margin: 0 0 4rem;
    }
  }
    .thematic__headline,
    .thematic__description {
      @include clearfix;
    }

    .thematic__block {
      padding-left: 15px;
      position: relative;

      @include clearfix;
    }

      .thematic__title {
        font-size: 4.5rem;
        font-weight: $extralight;
        line-height: 1;
        text-transform: uppercase;
        margin: 0 0 4rem;
      }

  .thematic__description {
    background-color: $black;
  }

    .thematic__img-first {
      margin-bottom: 8rem;
    }

    .thematic__text {
      font-weight: $extralight;
      line-height: 2rem;
      padding-right: 15px;

      p {
        margin: 4rem 0;
      }
    }

    .thematic__img-second {
      float: right;
      margin-top: 2rem;
    }

    .thematic__square {
      background-color: $cream-cran;
      bottom: 0;
      height: 6.2rem;
      left: 15px;
      position: absolute;
      visibility: hidden;
      width: 6.4rem;
    }

    .thematic__icon {
      height: 10rem;
      left: 50%;
      margin-left: -5rem;
      position: absolute;
      top: 1rem;
      width: 10rem;
      z-index: 10;

      .icon {
        height: 10rem;
        width: 10rem;
      }

      &:before {
        border: 1px dashed $white;
        border-radius: 5rem;
        content: '';
        display: block;
        height: 10rem;
        left: 0;
        position: absolute;
        top: 0;
        width: 10rem;
      }
    }

  .thematic__right {
    display: none !important;
  }

.studio {
  background-color: $cream-cran;
  color: $white;

  *[class^='col-'] {
    padding: 0;
  }
}
  .studio__left {
    display: none !important;
  }

  .studio__right {
    padding: 12rem 0 0;

    .button {
      bottom: 8rem;
      position: absolute;
      right: 15px;
    }
  }
    .studio__headline,
    .studio__description {
      @include clearfix;
    }

    .studio__block {
      padding-right: 15px;
      position: relative;

      @include clearfix;
    }

      .studio__title {
        color: $black;
        font-size: 4.5rem;
        font-weight: $extralight;
        line-height: 1;
        text-transform: uppercase;
        margin: 0 0 4rem;
        padding-left: 15px;
      }

  .studio__description {
    background-color: $heavy-metal;
  }

    .studio__text {
      font-weight: $extralight;
      line-height: 2rem;
      padding-left: 15px;
      margin-top: 8rem;

      p {
        margin: 4rem 0;
      }
    }

    .studio__square {
      background-color: $black;
      height: 17rem;
      margin: 10rem 0 13rem;
      visibility: hidden;
    }

    .studio__icon {
      height: 10rem;
      position: absolute;
      left: 50%;
      margin-left: -5rem;
      top: 1rem;
      width: 10rem;
      z-index: 10;

      .icon {
        height: 10rem;
        width: 10rem;
      }

      &:before {
        border: 1px dashed $white;
        border-radius: 5rem;
        content: '';
        display: block;
        height: 10rem;
        left: 0;
        position: absolute;
        top: 0;
        width: 10rem;
      }
    }

  .workshop {
    background: $white;
    color: $black;
    padding: 11rem 0 4rem;

    &--studio {
      padding-bottom: 0;
      position: relative;
    }
  }

  .workshop__title {
    font-size: 4.6rem;
    font-weight: $extralight;
    line-height: 1;
    margin: 0 0 4rem;
    text-align: center;
    text-transform: uppercase;
  }

  .workshop__text {
    line-height: 2.4rem;

    strong {
      font-size: 1.8rem;
    }

    p {
      margin: 2rem 0;
    }
  }

  .workshop__infos {
    font-size: 1.8rem;
    margin: 4rem 0 5rem;
    text-align: center;
  }

  .workshop__icon {
    display: block;
    height: 10rem;
    margin: 0 auto;
    width: 10rem;

    .workshop--studio & {
      animation-fill-mode: both;
      border: 1px dashed $white;
      border-radius: 8rem;
      height: 16rem;
      left: 50%;
      margin: 0 0 0 -8rem;
      padding: 3rem;
      position: absolute;
      top: -8rem;
      visibility: hidden;
      width: 16rem;

      .icon {
        height: 10rem;
        width: 10rem;
      }
    }
  }

  .workshop__link {
    color: $cream-cran;
    display: block;
    font-weight: $bold;
    text-decoration: none;
  }

  .workshop__movie {
    height: 0;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    position: relative;

    iframe {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  .workshop-slideshow {
    backface-visibility: hidden;
    margin-top: 4rem;
    overflow: hidden;
    width: 5100px;
    @include clearfix;
  }

  .workshop-slideshow__item {
    animation: moveSlideshow 60s linear infinite;
    float: left;
    transform: translate(0, 0);

    @include clearfix;
  }

  .workshop-slideshow__image {
    float: left;
    height: 280px;
    width: 425px;
  }

  @keyframes moveSlideshow {
    from {
      transform: translate(0, 0);
    }

    to {
      transform: translate(-100%, 0);
    }
  }

@media (min-width: $screen-sm) {
  .thematic__left {
    .button {
      display: none;
    }
  }

    .thematic__block {
      float: right;
      max-width: 100%;
      width: 570px
    }

    .thematic__text {
      padding-right: 5rem;
    }

    .thematic__icon {
      left: auto;
      margin-left: 0;
      margin-top: -5rem;
      right: -5rem;
      top: 50%;
    }

  .thematic__right {
    background-position: -1px center;
    background-repeat: no-repeat;
    background-size: cover;
    display: block !important;
    overflow: hidden;
    position: relative;

    .button {
      bottom: 10.7rem;
      left: 8.5rem;
      position: absolute;
      z-index: 10;
    }
  }

    .thematic__shadow {
      border-radius: 21rem;
      box-shadow: 0 0 0 1500px rgba(0, 0, 0, 0.5);
      height: 42rem;
      left: 0;
      margin: -21rem 0 0 -21rem;
      position: absolute;
      top: 50%;
      width: 42rem;
    }

  .studio__left {
    background-position: right center;
    background-repeat: no-repeat;
    background-size: cover;
    display: block !important;
    overflow: hidden;
    position: relative;
  }

    .studio__shadow {
      border-radius: 21rem;
      bottom: 9rem;
      box-shadow: 0 0 0 1500px rgba(0, 0, 0, 0.5);
      height: 42rem;
      position: absolute;
      right: -21rem;
      width: 42rem;
    }

  .studio__block {
    max-width: 100%;
    width: 570px
  }

  .studio__title {
    padding-left: 6.5rem;
  }

  .studio__text {
    padding-left: 6.5rem;
  }

  .studio__icon {
    bottom: 25rem;
    left: -5rem;
    margin-left: 0;
    top: auto;
  }

  .workshop-slideshow__image {
    height: 560px;
    width: 850px;
  }
}