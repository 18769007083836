// ==========================================================================
// Form
// ==========================================================================

button,
input,
optgroup,
select,
textarea {
  font-family: $font-default;
}

.form-item {
  margin: 1.6rem 0;
}