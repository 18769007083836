// ==========================================================================
// Page biography
// ==========================================================================

.page-biography {
  background: $black;
  color: $white;
  padding-bottom: 10rem;

  &--slideshow {
    padding-bottom: 0;
  }
}

.page-biography__header {
  position: relative;

  &:before {
    background: $heavy-metal;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 25%;
    top: 0;
    z-index: 5;
  }
}

.page-biography__title {
  background: $heavy-metal;
  display: inline-block;
  font-size: 2.2rem;
  font-weight: $extralight;
  line-height: 6rem;
  margin: 0;
  padding-right: 6rem;
  position: relative;
  z-index: 11;

  .icon {
    height: 4rem;
    margin-right: 1rem;
    width: 4rem;
  }
}

.page-biography__left {
  margin-top: -6rem;
  padding-right: 0;
  position: relative;
  z-index: 10;
}

.page-biography__right {
  padding-left: 0;
}

.page-biography__content {
  padding-top: 6rem;
  position: relative;

  h2 {
    color: $cream-cran;
    font-size: 2.2rem;
    font-weight: $extralight;
    line-height: 1.3;
    margin: 6rem 0 3rem;

    &:first-child {
      margin-top: 0;
    }
  }

  h3 {
    font-weight: $bold;
    line-height: 2.4rem;
    margin: 0;
    text-transform: uppercase;
  }

  p {
    margin: 0 0 2rem;
  }

  span {
    color: $cream-cran;
    display: block;
    font-size: 1.4rem;
    line-height: 1.8rem;
  }

  a {
    color: $cream-cran;
  }

  strong,
  b {
    color: $cream-cran;
    font-weight: $bold;
  }
}

.page-biography__content--left {
  padding: 6rem 15px 4rem 0;
}

.page-biography__content--right {
  padding-left: 12rem;
}

.page-biography__icon {
  border: 1px dashed $white;
  border-radius: 7rem;
  height: 14rem;
  margin-right: -7rem;
  padding: 3.6rem;
  position: absolute;
  right: 50%;
  top: -7rem;
  transform: rotate(-45deg);
  width: 14rem;

  &:before,
  &:after {
    background-color: $cream-cran;
    content: '';
    display: block;
    height: 0.3rem;
    margin-top: -0.15rem;
    position: absolute;
    top: 50%;
    width: 3.4rem;
  }

  &:before {
    left: -8rem;
  }

  &:after {
    right: -8rem;
  }

  .icon {
    height: 6.8rem;
    transform: rotate(45deg);
    width: 6.8rem;
  }
}

.page-biography__inner {
  padding-bottom: 4rem;
  position: relative;
  z-index: 1;

  &:before {
    background: $heavy-metal;
    bottom: 0;
    content: '';
    display: block;
    left: 0;
    position: absolute;
    top: 0;
    width: 1250px;
    z-index: -1;
  }

  &:after {
    clear: both;
    content: '';
    display: table;
  }
}

.page-biography__square {
  animation-fill-mode: both;
  background-color: $cream-cran;
  height: 6rem;
  margin-bottom: 2rem;
  width: 6rem;
}

.page-biography__rectangle {
  animation-fill-mode: both;
  float: right;
  background: $black;
  height: 6rem;
  margin-top: 6rem;
  width: 90%;
}

.page-slideshow {
  backface-visibility: hidden;
  margin-top: 4rem;
  overflow: hidden;
  width: 13760px;
  @include clearfix;
}

.page-slideshow__item {
  animation: moveSlideshow 60s linear infinite;
  float: left;
  transform: translate(0, 0);
  @include clearfix;
}

.page-slideshow__image {
  float: left;
  height: 280px;
  width: 420px;
}

@media (min-width: $screen-sm) {
  .page-biography__header {
    &:before {
      right: 50%;
    }
  }

  .page-biography__right {
    margin-top: -6rem;
  }

  .page-biography__icon {
    margin-right: 0;
    right: -7rem;
  }

  .page-slideshow__image {
    height: 560px;
    width: 860px;
  }
}