// ==========================================================================
// Utils
// ==========================================================================

.text--center {
  text-align: center;
}

.text--right {
  text-align: right;
}

.block-table {
  display: table;
  width: 100%;
}

.block-table__cell {
  display: table-cell;
  vertical-align: middle;
}

.action-links,
.sr-only {
  display: none;
}

.notices {
  bottom: 0;
  position: fixed;
  width: 100%;
  z-index: 15;

  .notices__close {
    display: none;
  }

  a {
    display: none;
  }

  .status,
  .error {
    background-image: none;
    color: $heavy-metal;
    font-size: 1.2rem;
    line-height: 1.8rem;
  }

  .status {
    background-color: $cream-cran;
  }

  .error {
    color: $white;
    background-color: #e73039;
  }

  .messages {
    border: 0 none;
    margin: 0;
    padding: 1.2rem 4.8rem 1.2rem 1.2rem;
  }

  .krumo-root {
    color: #000;
    border: 0 none;
    margin: 0;
    max-height: 25rem;
    overflow-y: scroll;
  }

  .dom-loaded & {
    animation-duration: 1s;
    animation-name: bounce;
    animation-iteration-count: 3;
  }
}

#tabs {
  font-size: 1.4rem;
  left: 0;
  line-height: 2.1rem;
  overflow: hidden;
  position: fixed;
  top: 204px;
  z-index: 15;

  .tabs.primary {
    border: 0 none;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      background-color: $heavy-metal;
      border: 1px solid $cream-cran;
      border-left: 0 none;
      display: block;
      margin: 0 0 15px;

      &.active {
        background-color: $cream-cran;

        a {
          color: $heavy-metal;
        }
      }
    }

    a {
      color: $white;
      display: block;
      padding: 10px 20px;
      text-align: center;
      text-decoration: none;
    }
  }
}

@media (min-width: 768px) {
  .notices {
    .error,
    .status {
      font-size: 1.6rem;
      line-height: 2.4rem;
    }

    .messages {
      padding: 1.5rem 5.4rem 1.5rem 1.5rem;
    }
  }
}