// ==========================================================================
// Page
// ==========================================================================

.page {
  background-color: $black;
  color: $white;
  padding-bottom: 10rem;
}

.page__header {
  position: relative;

  &:before {
    background: $heavy-metal;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 25%;
    top: 0;
  }
}

.page__title {
  font-size: 2.2rem;
  font-weight: $extralight;
  line-height: 6rem;
  margin: 0;
}

.page__content {

  h2 {
    color: $cream-cran;
    font-size: 2.2rem;
    font-weight: $extralight;
    line-height: 1.3;
    margin: 6rem 0 2rem;
  }

  p {
    margin-bottom: 2rem;
  }

  a {
    color: $cream-cran;
  }

  strong,
  b {
    font-weight: $bold;
  }
}

.page--login {
  padding-bottom: 0;

  .header__inner:before {
    background-color: $black;
    bottom: 0;
    content: '';
    display: block;
    left: 0;
    opacity: 0.5;
    position: absolute;
    right: 0;
    top: 0;
  }

  .page__content {
    text-align: left;

    h1 {
      font-size: 4.5rem;
      font-weight: $extralight;
      line-height: 1;
      text-transform: uppercase;
      margin: 0 0 4rem;
    }
  }
}

@media (min-width: $screen-sm) {
  .page__header {
    &:before {
      right: 50%;
    }
  }
}