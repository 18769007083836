// ==========================================================================
// Page thematic
// ==========================================================================

.term-listing-heading + p {
  display: none;
}

.page-thematic {
  background: $black;
  color: $white;
  padding-bottom: 10rem;
}

.page-thematic__header {
  position: relative;

  &:before {
    background: $heavy-metal;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 25%;
    top: 0;
    z-index: 5;
  }
}

.page-thematic__title {
  background: $heavy-metal;
  display: inline-block;
  font-size: 2.2rem;
  font-weight: $extralight;
  line-height: 6rem;
  margin: 0;
  padding-right: 6rem;
  position: relative;
  z-index: 11;

  .icon {
    height: 4rem;
    margin-right: 1rem;
    width: 4rem;
  }
}

.thematics-list {
  margin-top: 1rem;
  padding-top: 1rem;
  position: relative;

  &:before {
    background-color: #626262;
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    top: 0;
    width: 14rem;
  }
}

.thematics-list__text p {
  font-size: 1.4rem;
  font-weight: $medium;
  line-height: 2.4rem;
  margin: 0 0 2.4rem;
}

.page-thematic__description {
  margin-top: 4rem;
}

.thematic__mobile {
  text-align: center;
}

.thematics__gallery {
  margin: 2.4rem 0;
}

.thematics-list__title,
.thematics-list__title a {
  color: #626262;
  font-size: 1.6rem;
  font-weight: $bold;
  line-height: 3.6rem;
  margin: 0;
  text-decoration: none;

  &--active,
  &:hover {
    color: $cream-cran;
  }
}

.page-thematic__right {
  display: none;
}

.pswp__preloader--active .pswp__preloader__icn {
  background-image: url('../img/preloader.gif');
}

.pswp__button,
.pswp__button--arrow--left:before,
.pswp__button--arrow--right:before {
  background-image: url('../img/default-skin.png');
}

@media (-webkit-min-device-pixel-ratio: 1.1), (-webkit-min-device-pixel-ratio: 1.09375), (min-resolution: 105dpi), (min-resolution: 1.1dppx) {
  .pswp--svg .pswp__button,
  .pswp--svg .pswp__button--arrow--left:before,
  .pswp--svg .pswp__button--arrow--right:before {
    background-image: url('../img/svg/default-skin.svg');
  }
  .pswp--svg .pswp__button--arrow--left,
  .pswp--svg .pswp__button--arrow--right {
    background: none;
  }
}

.pswp__button--arrow--left,
.pswp__button--arrow--right {
  background: none;
}

@media (min-width: $screen-sm) {
  .page-thematic__header {
    &:before {
      right: 50%;
    }
  }

  .thematic__mobile {
    display: none;
  }

  .page-thematic__right {
    background-color: $black;
    display: block;
    margin-top: -6rem;
    padding: 0;
    position: relative;
    z-index: 15;
  }

  .thematic__slider {
    @include clearfix;

    .owl-dots {
      line-height: 12px;
      margin-top: 16px;
      text-align: center;
    }

    .owl-dot {
      background-color: $cream-cran;
      border: 0 none;
      border-radius: 6px;
      display: inline-block;
      height: 12px;
      margin: 0 6px;
      padding: 0;
      width: 12px;

      &.active {
        background-color: $white;
      }
    }
  }

  .thematic__button {
    background-color: transparent;
    border: 0 none;
    display: block;
    float: left;
    cursor: pointer;
    height: 21px;
    margin-top: -17px;
    padding: 0;
    position: relative;
    width: 23px;
    z-index: 5;
  }

  .thematic__button--right {
    float: right;
  }
}