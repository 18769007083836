// ==========================================================================
// Footer
// ==========================================================================

.footer {
  background: $heavy-metal;
  border-bottom: 7rem solid $heavy-metal;
  padding-top: 6rem;
}

.footer__section {
  margin: 2rem 0;
  text-align: center;
}

.footer__facebook {
  background: $cream-cran;
  border-radius: 4rem;
  display: inline-block;
  height: 8rem;
  padding: 1.4rem 2.8rem;
  width: 8rem;
}

.footer__icon {
  height: 5.2rem;
  width: 2.4rem;
}

.footer__logo {
  height: 24.4rem;
  max-width: 100%;
  width: 24.4rem;
}

.footer__address {
  color: $white;
  font-size: 2.2rem;
  font-style: normal;
  line-height: 3rem;
}

.footer__link {
  color: $cream-cran;
  display: block;
  font-size: 1.6rem;
  font-weight: $bold;
  text-decoration: none;
}

@media (min-width: $screen-sm) {
  .footer__section--left {
    padding-top: 8.2rem;
    text-align: right;
  }

  .footer__section--right {
    padding-top: 6.2rem;
    text-align: left;
  }
}