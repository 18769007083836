// ==========================================================================
// Input
// ==========================================================================

// Reset fuckin' ios8 input style
input[type='submit'] {
  -webkit-appearance: none;
  border-radius: 0;
}

// Reset fuckin' mozilla button/input spaces
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
  margin-top:-2px;
  margin-bottom: -2px;
}

input[type='text'],
input[type='email'],
input[type='password'],
.input {
  border: 1px solid $heavy-metal;
  color: $black;
  height: 4rem;
  line-height: 4rem;
  padding: 0 1rem;

  &::-ms-clear {
    display: none;
  }
  &::-ms-reveal {
    display: none;
  }
}

input[type='submit'] {
  background-color: transparent;
  border: 2px solid $cream-cran;
  color: $cream-cran;
  cursor: pointer;
  display: inline-block;
  font-size: 2.2rem;
  font-weight: $bold;
  line-height: 1;
  padding: 2rem 3rem;
  text-align: center;
  text-decoration: none;
  transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out;

  &:hover {
    background-color: $cream-cran;
    color: $black;
  }
}