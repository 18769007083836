// ==========================================================================
// Navigation
// ==========================================================================

.navigation {
  background-color: $heavy-metal;
  bottom: 0;
  position: fixed;
  right: 0;
  top: 0;
  text-align: center;
  transform: translate(100%, 0);
  transition: transform 0.5s ease;
  width: 75%;
  z-index: 30;

  .navigation--opened & {
    transform: translate(0, 0);
  }
}

.navigation__holder {
  height: 100vh;
  overflow-y: scroll;
  padding: 7rem 0 5rem;
}

.navigation__close {
  background-color: transparent;
  border: 0 none;
  cursor: pointer;
  margin-bottom: 5rem;
  padding: 0;

  .icon {
    height: 9.3rem;
    width: 9.3rem;
  }
}

.navigation__list {
  margin: 0 0 4rem;
  padding: 0;
}

.navigation__item {
  list-style-type: none;
}

.navigation__link {
  color: #626262;
  display: inline-block;
  font-size: 1.6rem;
  font-weight: $bold;
  line-height: 5rem;
  margin-bottom: 2rem;
  position: relative;
  text-decoration: none;

  &:hover {
    color: $cream-cran;
  }

  &--active {
    color: $cream-cran;

    &:after {
      border-top: 1px dashed $cream-cran;
      content: '';
      display: block;
      height: 1px;
      left: 50%;
      margin-left: -1.5rem;
      position: absolute;
      top: 3.5rem;
      width: 3rem;
    }
  }
}

.navigation__facebook {
  background: $cream-cran;
  border-radius: 2rem;
  display: inline-block;
  height: 3.7rem;
  margin-bottom: 1.5rem;
  padding: 0.7rem 1.3rem;
  width: 3.7rem;
}

  .navigation__icon {
    height: 2.3rem;
    width: 1.1rem;
  }

.navigation__address {
  color: $white;
  font-size: 2.2rem;
  font-style: normal;
  font-weight: $extralight;
  line-height: 3rem;
}

  .navigation__link--address {
    color: $cream-cran;
    display: block;
    font-size: 1.6rem;
    line-height: 3rem;
    margin-bottom: 0;
  }

.navigation__credits {
  border-top: 1px solid #626262;
  color: #626262;
  display: inline-block;
  font-size: 1.2rem;
  line-height: 4rem;
  margin-top: 1.5rem;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

@media (min-width: $screen-sm) {
  .navigation {
    width: 50%;
  }
}